import socketIO from "@/assets/js/socketIO";

const chat_with = {
  state: {
    arrChat: {
      user_id: 0,
    },
    friendList: [],
    newMsg: null,
    hasNotify: [],
    listUserOnline: [],
  },
  getters: {
    arrChat: (state) => {
      return state.arrChat;
    },

    friendList: (state) => {
      return state.friendList;
    },

    newMsg: (state) => {
      return state.newMsg;
    },

    hasNotify: (state) => {
      return state.hasNotify;
    },
  },
  mutations: {
    async addModuleChat(state, data) {
      if (state.arrChat.user_id) {
        await socketIO.leaveRoom(state.arrChat.user_id);
      }

      if (data.user_id != state.arrChat.user_id) {
        state.arrChat = data;
      }
    },

    async addFriendList(state, list) {
      state.friendList = list;
      await socketIO.setUserOnline();
    },

    setUserOnline(state) {
      state.friendList.forEach((friend) => {
        friend.isOnline = state.listUserOnline.includes(String(friend.user_id));
      });
    },

    setNewMsg(state, msg) {
      state.newMsg = msg;
    },

    setListUserOnline(state, userIds) {
      state.listUserOnline = userIds;
      console.write("store/set list user online ", state.listUserOnline);
    },

    clearNewMsg(state) {
      state.newMsg = null;
    },

    setHasNotify(state, idUserNotify) {
      state.hasNotify = idUserNotify;
      console.write("setHaveNotify", state.hasNotify);
    },
  },
  actions: {
    addModuleChat({ commit }, data) {
      commit("addModuleChat", data);
    },

    addFriendList({ commit }, data) {
      commit("addFriendList", data);
    },

    setUserOnline({ commit }) {
      commit("setUserOnline");
    },

    setUserNotify({ commit }) {
      socketIO
        .alertNotify()
        .then((idUserNotify) => {
          commit("setHasNotify", idUserNotify);
        })
        .catch((error) => {
          console.write("error loadNewMsg", error.message);
        });
    },

    loadNewMsg({ commit }) {
      commit("clearNewMsg");
      socketIO
        .loadNewMsg()
        .then(() => {
          console.log("new msg");
          return 1;
        })
        .catch((error) => {
          console.write("error loadNewMsg", error.message);
        });
    },
  },
};
export default chat_with;
