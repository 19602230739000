import axios from "@/assets/js/axios";
const state = {
  token: localStorage.getItem("token") || null,
  userId: localStorage.getItem("userId") || null,
  username: localStorage.getItem("username") || null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  getToken: (state) => state.token,
  getUserId: (state) => state.userId,
};

const mutations = {
  setToken(state, data) {
    state.token = data;
  },
  clearAuthData(state) {
    state.token = null;
    state.userId = null;
    state.username = null;
  },
  setUserId(state, data) {
    state.userId = data;
  },
  setUsername(state, data) {
    state.username = data;
  },
};

const actions = {
  async login({ commit, dispatch }, data) {
    try {
      const response = await axios.post("auth/login", data);
      if (response.status == 200) {
        let res = response.data;
        dispatch("swal/success", res.message);
        commit("setToken", res.data.accessToken);
        commit("setUserId", res.data.userId);
        commit("setUsername", res.data.username);
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem("userId", res.data.userId);
        localStorage.setItem("username", res.data.username);
      }

      setTimeout(() => {
        let redirect =
          window.location.pathname == "/admin/login" ? "/admin" : "/";
        window.location.href = redirect;
      }, 2000);
    } catch (error) {
      console.write("error Login", error.message);
      dispatch("swal/error", `Login Fail`);
    }
  },
  async logout({ commit, dispatch }) {
    await axios
      .post("auth/logout")
      .then((response) => {
        commit("clearAuthData");
        localStorage.clear()
        dispatch("swal/success", response.data.message);
        setTimeout(() => {
          window.location.href = "/user/login";
        }, 1500);
      })
      .catch((error) => {
        console.write("error Logout", error.message);
        dispatch("swal/error", `Logout Fail`);
      });
  },
  getToken({ commit }, user) {
    commit("setUserId", user);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
