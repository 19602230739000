<template>
    <div id="web-layout" class="h-auto">
        <HeaderVue />
        <main id="main-content" class="mt-0 bg-purple-100 relative">
            <router-view />
        </main>
    </div>
</template>

<script>
import socketIO from '@/assets/js/socketIO';
import HeaderVue from './Web/Components/HeaderWeb/HeaderVue.vue';
export default {
    name: 'WebLayout',
    components: {
        HeaderVue,
    },
    beforeUnmount() {
        this.disconnectSocket()
    },
    methods: {
        async disconnectSocket() {
            await socketIO.disconnectSocket()
        }
    }
}
</script>


<style scoped>
/* sm */
@media only screen and (min-width: 640px) {
    #web-layout #main-content {
        height: calc(100vh - 95px);
    }
}

/* md */
@media only screen and (min-width: 768px) {
    #web-layout #main-content {
        height: calc(100vh - 51px);
    }
}

/* lg */
@media only screen and (min-width: 1024px) {}
</style>