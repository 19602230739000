<template>
    <header id="header" class="flex bg-blue-400 px-2">
        <MenuVue />
        <ConfigTheme />
    </header>
</template>
<script>
import ConfigTheme from './modules/ConfigTheme.vue';
import MenuVue from './modules/Menu.vue';


export default {
name: 'HeaderVue',
components: {
    ConfigTheme,
    MenuVue
}
}
</script>