/**
 * Ghi log vào console với định dạng tùy chỉnh.
 *
 * @param {string} [logName="Log Action"] - Tên của log, mặc định là "Log Action".
 * @param {...*} args - Các đối số bổ sung sẽ được ghi log.
 * @returns {void|Function} - Nếu môi trường là "local", hàm sẽ ghi log; ngược lại, không làm gì.
 */
window.console.write = (logName = "Log Action", ...args) => {
    if (process.env.VUE_APP_ENV == "local") {
      console.log(`-------- ${logName} --------`);
      console.log(...args);
      console.log();
    } else {
      return () => {}
    }
  };
  