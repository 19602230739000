<script setup>
import bgLogin from "@/assets/imgs/app/be-bg-login.png"
import logo from "@/assets/imgs/app/dev-logo.png"
</script>
<template>
    <section id="user-layout" :style="{ backgroundImage: `url(${bgLogin})` }"
        class="bg-no-repeat bg-center max-h-screen">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
            <a href="#" class="flex items-center mb-3 text-2xl font-semibold text-gray-900 dark:text-white">
                <img class="w-36 mr-2 rounded-md" :src="logo" alt="logo" />
            </a>
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    name: 'UserLayout',
    data() {
        return {
        }
    }
}
</script>