import { createI18n } from "vue-i18n";

// import base language
import vi from "@/language/vi.json";
import en from "@/language/en.json";

// get locale from .env
const locale = process.env.VUE_APP_I18N_LOCALE;
const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE;

const i18n = createI18n({
  locale: locale || "en",
  fallbackLocale: fallbackLocale || "en",
  messages: {
    en,
    vi,
  },
});
export default i18n;