<template>
    <div class="bg-[#ffffff90] fixed flex h-screen items-center justify-center w-screen" style="z-index: 100000">
        <img id="loading" src="../assets/imgs/app/loading.gif" width="100" alt="">
    </div>
</template>

<script>
export default ({
    name: 'LoadingVue',
})
</script>
<style>

#loading {
    animation: bounce 1s infinite;
    transition: .4;
}
@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}
</style>