<template>
    <div id="header-right" class="flex flex-col w-auto place-content-center">
        <span>
            <label class="inline-flex items-center me-5 cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" v-model="theme">
                <div
                    class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-black-300 dark:peer-focus:ring-black-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-800">
                </div>
                <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ theme ? $t('Dark Mode') : $t('Light Mode') }}</span>
            </label>
        </span>
        <select id="languages" @click="changeLanguage(lang)" v-model="lang"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white outline-none dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option value="vi" :selected="lang == 'vi'">{{ $t('Vietnamese') }}</option>
            <option value="en" :selected="lang == 'en'">{{ $t('English') }}</option>
        </select>
    </div>
</template>
<script>
export default {
    name: 'ConfigTheme',
    data() {
        return {
            lang: 'en',
            theme: false,
        }
    },
    methods: {
        changeLanguage(lang) {
            this.$i18n.locale = lang;
        }
    }
};
</script>