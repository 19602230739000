<template>
    <header id="header-web" class="flex bg-blue-50 border-b-2 border w-full md:h-auto h-auto py-2">
        <!-- <div class="px-3 bg-white w-full"> -->
        <div class="px-3 w-full h-full flex sm:flex-row flex-col justify-between items-center">
            <!-- search -->
            <form action="" @submit.prevent="console.log('submit')" autocomplete="off" class="sm:w-[310px] w-full flex">
                <img src="../../../../assets/imgs/app/dev-logo.png" class="h-8 me-2 object-cover rounded-md" alt="">
                <input type="text" name="search" id="" v-model="keysearch"
                    class="outline-none border rounded-s-md px-2 text-xs h-8 flex-1" placeholder="Nội dung tìm kiếm"
                    @keypress.enter="submit">
                <button type="submit"
                    class="h-8 rounded-e-md border-blue-300 bg-blue-300 hover:bg-blue-400 hover:text-white text-gray-600 text-xs border px-3">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </button>
            </form>

            <div class="sm:m-0 mt-3 flex-1 flex sm:w-auto w-full justify-between">
                <div class="w-1/2 flex sm:justify-end justify-between">
                    <MenuPage class="" />
                </div>
                <div class="sm:hidden block pe-2"><div class="border border-r-0 border-y-0 border-l-1 border-gray-400 h-full mx-2"></div></div>
                <ActionWeb />
            </div>
        </div>
    </header>
</template>

<script>
import ActionWeb from './module/ActionWeb.vue';
import MenuPage from './module/MenuPage.vue';

export default {
    name: 'HeaderVue',
    components: {
        MenuPage,
        ActionWeb
    },
    data() {
        return {
            keysearch: ''
        }
    }
}
</script>