<template>
    <ul class="list-none flex justify-center sm:gap-x-3 gap-x-1">
        <router-link :to="{name: 'HomeWeb'}" class="py-1 text-gray-700 sm:w-14 w-10 text-center hover:border-b-2 cursor-pointer border-blue-500 px-2 border-0 rounded-md hover:bg-blue-300 h-8">
            <i class="fa-solid fa-house-chimney"></i>
        </router-link>
        <router-link :to="{name: 'HomeWeb'}" class="py-1 text-gray-700 sm:w-14 w-10 text-center hover:border-b-2 cursor-pointer border-blue-500 px-2 border-0 rounded-md hover:bg-blue-300 h-8">
            <i class="fa-solid fa-user-group"></i>
        </router-link>
        <router-link :to="{name: 'HomeWeb'}" class="text-gray-700 sm:w-14 w-10 text-center hover:border-b-2 cursor-pointer border-blue-500 px-2 border-0 rounded-md hover:bg-blue-300 h-8 text-lg">
            <i class="fa-brands fa-square-youtube"></i>
        </router-link>
        <router-link :to="{name: 'HomeWeb'}" class="py-1 text-gray-700 sm:w-14 w-10 text-center hover:border-b-2 cursor-pointer border-blue-500 px-2 border-0 rounded-md hover:bg-blue-300 h-8">
            <i class="fa-solid fa-users-between-lines"></i>
        </router-link>
        
    </ul>
</template>

<script>
export default {
    name: 'MenuPage'
}
</script>
