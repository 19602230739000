import store from "@/store";
import { io } from "socket.io-client";
import { alertSocket } from "./swal";

class SocketIO {
  socket = null;
  constructor() {
    this.token = localStorage.getItem("token");
    this.userId = localStorage.getItem("userId");
    if (this.token) {
      this.socket = io(
        process.env.VUE_APP_SOCKET_URL || "http://localhost:3000",
        {
          query: {
            userId: this.userId,
          },
        }
      );
      this.socket.on("connection", async (data) => {
        console.log(data.msg);
      });
    } else {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
    }
  }

  setUserOnline() {
    this.socket.on("getUsersOnline", async (data) => {
      await store.commit("setListUserOnline", data.userIds);
      await store.dispatch("setUserOnline");
      if (data.newOnline && data.idNewUserUpdate != this.userId) {
        setTimeout(() => {
          alertSocket(1, data.nameNewUserUpdate);
        }, 2000);
        return 1;
      } else if (data.newOffline && data.idNewUserUpdate != this.userId) {
        alertSocket(0, data.nameNewUserUpdate);
        return 1;
      }
    });
    return () => this.socket.close();
  }

  joinRoom(receiver) {
    let sender = this.userId;
    let room =
      sender < receiver
        ? `room chat: ${sender} vs ${receiver}`
        : `room chat: ${receiver} vs ${sender}`;
    console.write("joinRoom", room);

    return this.socket.emit("joinRoom", room);
  }

  leaveRoom(receiver) {
    let sender = this.userId;
    let room =
      sender < receiver
        ? `room chat: ${sender} vs ${receiver}`
        : `room chat: ${receiver} vs ${sender}`;
    console.write("leaveRoom", room);

    return this.socket.emit("leaveRoom", room);
  }

  sendMessage(data) {
    let sender = this.userId;
    let receiver = data.receiver;
    data.sender = sender;
    // console.write("sendMessage", data);

    let room =
      sender < receiver
        ? `room chat: ${sender} vs ${receiver}`
        : `room chat: ${receiver} vs ${sender}`;

    return this.socket.emit("sendMessage", { room: room, data: data });
  }

  loadNewMsg() {
    return new Promise((resolve) => {
      this.socket.on("receiveMessage", (server) => {
        if (server.status) {
          let receiverSound = new Audio(
            require("@/assets/soundEffect/mixkit-bubble-pop-up-alert-notification-2357.mp3")
          );
          store.commit("setNewMsg", server.data);
          if (server.data.receiver == this.userId) {
            receiverSound.play();
          }
          console.write("loadNewMsg", server.data);
          resolve(server.data); // Resolve the promise with the new message data
        } else {
          store.dispatch(
            "swal/error",
            this.$t("Something went wrong", {
              nameErr: this.$t("Load New Message"),
            })
          );
          resolve(null); // Resolve with null or handle accordingly
        }
      });
    });
  }
  alertNotify() {
    // console.log('alert notify');
    
    // this.socket.on("alertNotify", (idUserNotify) => {
    //   console.write("alert notify", idUserNotify);
    // });
    // return () => this.socket.close();
    return new Promise((resolve) => {
      this.socket.on("alertNotify", (idUserNotify) => {
        if (idUserNotify) {
          console.write('alert notify', idUserNotify);
          resolve(idUserNotify);
        } else {
          store.dispatch(
            "swal/error",
            this.$t("Something went wrong", {
              nameErr: this.$t("Notify Message"),
            })
          );
          resolve(null);
        }
      });
    });
  }

  disconnectSocket() {
    return this.socket.disconnect();
  }
}
export default new SocketIO();
