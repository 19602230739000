<template>
    <nav id="header-left" class="flex flex-1 h-auto place-content-center p-2">
        <router-link
            class="min-w-24 text-center bg-white p-1 rounded-lg block mx-1 place-content-center hover:scale-105 transition-all"
            :to="{ name: 'dashboard' }">
            <img class="object-cover mx-auto sm:h-8 h-5" src="../../../../assets/imgs/icons/dashboard.png" alt="">
            <span>{{ $t('Dashboard') }}</span>
        </router-link>
        <router-link
            class="min-w-24 text-center bg-white p-1 rounded-lg block mx-1 place-content-center hover:scale-105 transition-all"
            :to="{ name: 'languages' }">
            <img class="object-cover mx-auto sm:h-8 h-5" src="../../../../assets/imgs/icons/translate.png" alt="">
            <span>{{ $t('Translate') }}</span>
        </router-link>
    </nav>
</template>
<script>
export default {
name: 'MenuVue'
}
</script>