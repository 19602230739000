import axios from "@/assets/js/axios";
class Conversation {
  async getConversationList() {
    return await axios.get("chat/conversations");
  }

  async getMessagesForConversation(conversationId) {
    return await axios.get(`chat/conversation/${conversationId}`);
  }
}

export default new Conversation();
