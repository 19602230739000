<!-- LoadModule -->
<template>
    <div v-if="loading" class="bg-[#ffffff90] absolute flex h-full items-center justify-center w-full"
        style="z-index: 100000">
        <img id="loading" src="../assets/imgs/app/loading.gif" width="50" alt="">
    </div>
</template>

<script>
import { debounce } from 'lodash';
export default ({
    name: 'LoadModule',
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: true,
        };
    },

    watch: {
        isLoading(value) {
            if (value == true) {
                this.loading = value;
                return;
            }
            this.delayLoading(value);
        },
    },
    methods: {
        delayLoading: debounce(function () {
            this.loading = false;
        }, 500),
    },
})
</script>
<style>
#loading {
    animation: bounce 1s infinite;
    transition: .4;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}
</style>