<template>
  <LoadingVue v-if="loading" />
  <router-view />
</template>


<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  watch: {
    isLoading(value) {
      if (value == true) {
        this.loading = value;
        return;
      }
      this.delayLoading(value);
    },
  },
  methods: {
    delayLoading: debounce(function () {
      this.loading = false;
    }, 500),
  },
}
</script>
<style></style>
