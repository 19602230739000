import { statusNotify } from "@/assets/js/swal.js";
export default {
  namespaced: true,
  actions: {
    success(_, message) {
      statusNotify("success", message);
    },
    error(_, message) {
      statusNotify("error", message);
    },
  },
};
