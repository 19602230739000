import Vuex from 'vuex';
import auth from './modules/auth.js';
import swal from './modules/swal.js';
import global_data from './modules/global_data.js';
import chat_with from './modules/chat_with.js';

export default new Vuex.Store({
  modules: {
    auth,
    swal,
    global_data,
    chat_with,
  },
});
