<template>
    <div id="admin-layout" class="bg-blue-50 h-screen">
        <div class="container bg-white mx-auto h-full flex flex-col">
            <HeaderVue />
            <div class="bg-white flex-1 text-red-500">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderVue from "./Admin/components/Header.vue"

export default {
    name: 'AdminLayout',
    components: {
        HeaderVue,
    },
}
</script>