import { isLogin, isAdmin } from "@/middleware/auth";
import AdminLayout from "@/views/AdminLayout.vue";
import UserLayout from "@/views/Web/user/UserLayout.vue";
import WebLayout from "@/views/WebLayout.vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: WebLayout,
    beforeEnter: isLogin,
    children: [
      {
        path: "",
        name: "HomeWeb",
        component: () => import(
          /* webpackChunkName: "webHome" */ "@/views/Web/Home/HomeVue.vue"
        ),
      },
    ],
  },
  {
    path: "/user",
    component: UserLayout,
    children: [
      {
        path: "login",
        name: "webLogin",
        component: () => import(
          /* webpackChunkName: "webLogin" */ "@/views/Web/user/WebLogin.vue"
        ),
      },
      {
        path: "register",
        name: "webRegister",
        component: () => import(
          /* webpackChunkName: "WebRegister" */ "@/views/Web/user/WebRegister.vue"
        ),
      },
    ],
  },
  {
    path: "/admin",
    component: AdminLayout,
    beforeEnter: isAdmin,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import(
          /* webpackChunkName: "dashboard" */ "@/views/Admin/Dashboard/Dashboard.vue"
        ),
      },
      {
        path: "config/translate",
        name: "languages",
        component: () => import(
          /* webpackChunkName: "translate" */ "@/views/Admin/Languages/Languages.vue"
        ),
      },
    ],
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: () => import(
      /* webpackChunkName: "adminLogin" */ "@/views/Admin/AdminLogin.vue"
    ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
