<template>
    <div id="ConversationList"
        class="p-2 pb-1 md:w-72 w-screen min-h-11 bg-white shadow-lg border border-gray-200 rounded-lg md:absolute fixed md:right-0 right-0 md:top-8 top-24 z-50">
        <ul v-if="conversationList.length">
            <li class="mb-1 p-1 items-center gap-x-2 flex rounded-md cursor-pointer border-b border-gray-100 hover:bg-blue-200 transition-all"
                v-for="(item, index) in conversationList" :key="index">
                <div class="bg-green-500 rounded-full h-10 w-10 overflow-hidden">
                    <img src="../../../../../assets/imgs/app/avatar.png" class="h-full w-full object-cover" alt="">
                </div>
                {{ item.receiver.username }}
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'ConversationList',
    props: {
        conversationList: {
            default: []
        },
    },
    data() {
        return {
            loading: true
        }
    },
}
</script>