import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import i18n from "./i18n";
import "@/assets/js/config";

const app = createApp(App);
//
import LoadingVue from "./common/LoadingVue.vue";
import LoadModule from "./common/LoadModule.vue";
//
app.component("LoadModule", LoadModule);
app.component("LoadingVue", LoadingVue);
//
app.use(i18n);
app.use(store);
app.use(router);
app.mount("#app");
