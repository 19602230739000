import i18n from "@/i18n";
import Swal from "sweetalert2";

const statusNotify = (type, message) => {
  Swal.fire({
    icon: type || "success",
    text: message || "Success title notify",
    showConfirmButton: false,
    timerProgressBar: true,
    toast: true,
    position: "top-end",
    width: "auto",
    timer: 3000,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
};
const toastConfirm = (message, yes = "Yes", no = "No") => {
  return Swal.fire({
    text: i18n.global.t(message) || "Confirm",
    icon: "warning",
    showCancelButton: true,
    cancelButtonColor: "#b6d9ff",
    cancelButtonText: i18n.global.t(no),
    confirmButtonColor: "#f38021",
    confirmButtonText: i18n.global.t(yes),
  })
};

const alertSocket = (isOnline = 1, user = 'Admin') => {
  let type = ''
  let textMsg = ''  
  if (isOnline) {
    type = "success"
    textMsg = i18n.global.t('UserOnline', { user: `<strong>${user}</strong>` })    
  } else {
    type = "error"
    textMsg = i18n.global.t('UserOffline', { user: `<strong>${user}</strong>` })
  }
  Swal.fire({
    icon: type,
    html: textMsg,
    showConfirmButton: false,
    toast: true,
    position: "top-end",
    width: "auto",
    timer: 3000,
  });
}

export { statusNotify, toastConfirm, alertSocket };
