<template>
    <ul class="list-none flex gap-x-3">
        <li
            class="text-gray-700 w-10 py-1 text-center hover:border-b-2 cursor-pointer border-blue-500 px-2 border-0 rounded-md hover:bg-blue-300 h-8">
            <i class="fa-regular fa-bell"></i>
        </li>
        <li class="relative">
            <div class="text-gray-700 w-10 py-1 text-center cursor-pointer border-blue-500 px-2 border-0 rounded-md hover:bg-blue-300 h-8"
                :class="showNotify ? ' bg-blue-300' : ''" @click="showNotify = !showNotify">
                <i class="fa-regular fa-comments"></i>
            </div>
            <ConversationList v-if="showNotify" :conversationList="conversationList" />
        </li>
        <li class="text-gray-700 py-1 text-center hover:border-b-2 cursor-pointer border-blue-500 px-2 border-0 rounded-md hover:bg-blue-300 h-8 relative"
            @click="showMenuUser = !showMenuUser">
            <span class="me-2 select-none">{{ username }}</span>
            <i class="fa-regular fa-address-card"></i>
            <ul class="absolute bg-white shadow-md border border-gray-50 right-0 z-50 w-44 p-1 rounded-md cursor-default flex gap-y-2 flex-col"
                v-if="showMenuUser">
                <li @click="lognow()" class="bg-white rounded-md cursor-pointer py-1 hover:bg-blue-200 transition-all">
                    {{ $t('Account') }}
                </li>
                <li @click="logout()" class="bg-white rounded-md cursor-pointer py-1 hover:bg-blue-200 transition-all">
                    {{ $t('Logout') }}
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
import Conversation from '@/services/Chat/Conversation';
import ConversationList from './ConversationList.vue';
import { toastConfirm } from '@/assets/js/swal';

export default {
    name: 'ActionWeb',
    components: {
        ConversationList
    },
    data() {
        return {
            showNotify: false,
            showMenuUser: false,
            conversationList: [],
            username: ''
        }
    },
    created() {
        this.username = this.$store.state.auth.username;
        this.getConversationList();
    },
    methods: {
        getConversationList() {
            Conversation.getConversationList()
                .then((response) => {
                    this.conversationList = response.data.data;
                })
                .catch((error) => {
                    console.error(error);
                })

        },
        logout() {
            toastConfirm('You want to log out of your account from this device.')
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('logout')
                    }
                    return true;
                })
                .catch((errors) => {
                    console.log(errors.message);
                })
        },
        async lognow() {
            console.log('lognowss');
        }
    }
}
</script>
