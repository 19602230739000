import i18n from "@/i18n";
import store from "@/store";
import { jwtDecode } from "jwt-decode";

// Example of authentication middleware
export function isLogin(to, from, next) {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        store.dispatch(
          "swal/error",
          i18n.global.t("Login session has expired")
        );
        return next("/user/login"); // Redirect to login
      }
      next(); // Continue to the next route
    } catch (error) {
      console.write("error IsLogin", error.message);
    }
  } else {
    return next("/user/login"); // Redirect to login
  }
}
// Example of authentication middleware
export function isAdmin(to, from, next) {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        return next("/admin/login"); // Redirect to login
      }
      next(); // Continue to the next route
    } catch (error) {
      console.write("error isAdmin", error.message);
    }
  } else {
    return next("/admin/login"); // Redirect to login
  }
}
