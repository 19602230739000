import axios from "axios";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);

axios.defaults.baseURL = process.env.VUE_APP_URL || "http://localhost:3000/api/";
axios.defaults.headers.common["Content-Type"] = "application/json";

const token = localStorage.getItem("token") || null;
if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}
axios.defaults.withCredentials = false;
export default axios;
